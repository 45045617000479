.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.mobileContainer {
  padding: 1rem;
  gap: 1.5rem;
}

.pageTitle {
  text-align: center;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

/* Add toolbar styles */
.optionsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
}

.mobileOptionsContainer {
  grid-template-columns: 1fr;
  gap: 1rem;
}

.optionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  background: rgba(var(--navbar-brown), 0.3);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  color: white;
}

/* Compact version of option cards */
.compactOption {
  padding: 1.75rem 1.5rem;
}

.optionCard:hover {
  transform: translateY(-2px);
  background: rgba(var(--navbar-brown), 0.4);
  border-color: var(--chair-orange);
}

.icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--chair-orange);
}

.compactOption .icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.optionCard h2 {
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}

.compactOption h2 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.optionCard p {
  color: white;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 280px;
}

.compactOption p {
  font-size: 0.95rem;
  max-width: 100%;
}

.descriptionDetail {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  opacity: 0.85;
  font-style: italic;
}

/* Tabs container styles */
.tabsContainer {
  display: flex;
  flex-direction: column;
  background: rgba(var(--navbar-brown), 0.3);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tabButtons {
  display: flex;
  background: rgba(var(--navbar-brown), 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobileTabButtons {
  flex-direction: row;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
}

.mobileTabButtons::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.tabButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  background: transparent;
  border: none;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  color: white;
}

.mobileTabButton {
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
}

.tabIcon {
  font-size: 1.25rem;
}

.tabButton:hover {
  color: var(--chair-orange);
}

.activeTab {
  color: var(--chair-orange);
  border-bottom-color: var(--chair-orange);
  background: rgba(var(--navbar-brown), 0.5);
}

.tabContent {
  padding: 1.75rem;
  min-height: 300px;
}

.contentList {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.emptyMessage {
  color: var(--text-secondary);
  text-align: center;
  font-style: italic;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  opacity: 0.8;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .pageTitle {
    font-size: 1.75rem;
  }

  .optionCard {
    padding: 1.5rem;
  }

  .optionCard h2 {
    font-size: 1.25rem;
  }

  .icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .tabContent {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .pageTitle {
    font-size: 1.5rem;
  }

  .optionCard {
    padding: 1.25rem;
  }

  .optionCard p {
    font-size: 0.9rem;
  }
}
