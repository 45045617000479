.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal {
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  max-height: 90vh;
  overflow-y: auto;
  animation: dialogEnter 0.2s ease-out;
  z-index: 1001;
  position: relative;
  padding: 0;
}

@keyframes dialogEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--chair-orange);
  margin: 0;
}

.content {
  padding: 24px;
  text-align: center;
  color: var(--text-secondary);
  line-height: 1.6;
  font-size: 1rem;
  margin: 0;
}

.errorMessage {
  color: var(--error);
  background-color: rgba(var(--error-rgb), 0.1);
  padding: 12px;
  margin: 0 24px 16px;
  border-radius: 8px;
  text-align: center;
  font-size: 0.9rem;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 0 24px 24px;
}

.button {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  min-width: 100px;
}

.cancelButton {
  background-color: #f0f0f0;
  color: var(--text-primary);
  border: none;
}

.cancelButton:hover {
  background-color: #e0e0e0;
}

.deleteButton {
  background-color: var(--error);
  color: white;
  border: none;
}

.deleteButton:hover {
  background-color: var(--error-dark, #c62828);
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.deleteButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Animation for view transitions */
.errorView {
  animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .header {
    padding: 16px;
    font-size: 1.1rem;
  }

  .content {
    padding: 16px;
    font-size: 0.95rem;
  }

  .actions {
    padding: 0 16px 16px;
  }

  .button {
    padding: 10px 16px;
    font-size: 0.9rem;
  }
}

.closeButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #333;
}
