/* Theme variables */
:root {
  --light-bg-primary: #ffffff;
  --light-bg-secondary: #f9f9f9;
  --light-text-primary: #333333;
  --light-text-secondary: #666666;
  --light-border: #e0e0e0;
  --light-input-bg: #ffffff;
  --light-shadow: rgba(0, 0, 0, 0.08);
  --light-error: #e53935;
  --light-success: #43a047;

  --dark-bg-primary: #1a1a1a;
  --dark-bg-secondary: #2a2a2a;
  --dark-text-primary: #e1e1e1;
  --dark-text-secondary: #b0b0b0;
  --dark-border: #444444;
  --dark-input-bg: #2a2a2a;
  --dark-shadow: rgba(0, 0, 0, 0.2);
  --dark-error: #ff6b6b;
  --dark-success: #66bb6a;

  --transparent-bg-primary: transparent;
  --transparent-bg-secondary: rgba(255, 255, 255, 0.05);
  --transparent-text-primary: #e1e1e1;
  --transparent-text-secondary: #b0b0b0;
  --transparent-border: rgba(255, 255, 255, 0.2);
  --transparent-input-bg: rgba(255, 255, 255, 0.1);
  --transparent-shadow: none;
  --transparent-error: #ff6b6b;
  --transparent-success: #66bb6a;
}

/* Base container */
.container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh; /* Take up full viewport height */
  transition: all 0.3s ease;
}

/* Light theme (default) */
.container,
.light {
  background-color: var(--light-bg-primary);
  color: var(--light-text-primary);
  box-shadow: 0 4px 20px var(--light-shadow);
}

/* Dark theme */
.dark {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  box-shadow: 0 4px 20px var(--dark-shadow);
}

/* Transparent theme */
.transparent {
  background-color: var(--transparent-bg-primary);
  color: var(--transparent-text-primary);
  box-shadow: var(--transparent-shadow);
}

.formHeader {
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: 2rem auto;
  padding: 0 2rem;
}

/* Theme-specific header styles */
.light .formHeader .formTitle {
  color: var(--light-text-primary);
}

.dark .formHeader .formTitle {
  color: var(--dark-text-primary);
}

.transparent .formHeader .formTitle {
  color: var(--transparent-text-primary);
}

.formTitle {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Theme-specific description styles */
.light .formDescription {
  color: var(--light-text-secondary);
}

.dark .formDescription {
  color: var(--dark-text-secondary);
}

.transparent .formDescription {
  color: var(--transparent-text-secondary);
}

.formDescription {
  font-size: 1rem;
  line-height: 1.5;
}

.formContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 800px; /* Set max width for content */
  width: 100%;
  margin: 0 auto; /* Center the content */
  padding: 0 2rem; /* Add horizontal padding */
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Theme-specific label styles */
.light .label {
  color: var(--light-text-primary);
}

.dark .label {
  color: var(--dark-text-primary);
}

.transparent .label {
  color: var(--transparent-text-primary);
}

.label {
  font-weight: 500;
  font-size: 1rem;
}

.labelWithTooltip {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Theme-specific icon styles */
.light .infoIcon {
  color: var(--light-text-secondary);
}

.dark .infoIcon {
  color: var(--dark-text-secondary);
}

.transparent .infoIcon {
  color: var(--transparent-text-secondary);
}

.infoIcon {
  font-size: 0.9rem;
  cursor: help;
}

/* Theme-specific input styles */
.light .input {
  border: 1px solid var(--light-border);
  background-color: var(--light-input-bg);
  color: var(--light-text-primary);
}

.dark .input {
  border: 1px solid var(--dark-border);
  background-color: var(--dark-input-bg);
  color: var(--dark-text-primary);
}

.transparent .input {
  border: 1px solid var(--transparent-border);
  background-color: var(--transparent-input-bg);
  color: var(--transparent-text-primary);
}

.input {
  padding: 0.75rem;
  border-radius: 8px;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  transition: border-color 0.2s ease;
}

.input:focus {
  outline: none;
  border-color: var(--chair-orange);
  box-shadow: 0 0 0 2px rgba(255, 123, 0, 0.1);
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
}

/* Theme-specific placeholder styles */
.light .input::placeholder {
  color: #aaaaaa;
}

.dark .input::placeholder {
  color: #777777;
}

.transparent .input::placeholder {
  color: #777777;
}

/* Theme-specific body styles */
.light .body {
  border: 1px solid var(--light-border);
  background-color: var(--light-input-bg);
  color: var(--light-text-primary);
}

.dark .body {
  border: 1px solid var(--dark-border);
  background-color: var(--dark-input-bg);
  color: var(--dark-text-primary);
}

.transparent .body {
  border: 1px solid var(--transparent-border);
  background-color: var(--transparent-input-bg);
  color: var(--transparent-text-primary);
}

.body {
  min-height: 300px; /* Increased for a more Medium-like feel */
  border-radius: 8px;
  padding: 0.75rem;
  transition: border-color 0.2s ease;
}

.body:focus-within {
  border-color: var(--chair-orange);
  box-shadow: 0 0 0 2px rgba(255, 123, 0, 0.1);
}

.body div[class*="ProseMirror-focused"] {
  outline: none;
}

/* Theme-specific placeholder styles */
.light
  .body
  div[class*="tiptap ProseMirror"]
  p[class*="is-editor-empty"]:first-child::before {
  color: #aaaaaa;
}

.dark
  .body
  div[class*="tiptap ProseMirror"]
  p[class*="is-editor-empty"]:first-child::before {
  color: #777777;
}

.transparent
  .body
  div[class*="tiptap ProseMirror"]
  p[class*="is-editor-empty"]:first-child::before {
  color: #777777;
}

.body div[class*="tiptap ProseMirror"] {
  p[class*="is-editor-empty"]:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  > * {
    margin-bottom: 0.75rem;
  }

  :first-child {
    margin-top: 0;
  }

  p {
    line-height: 1.8; /* Increased for a more Medium-like feel */
    font-size: 1.05rem; /* Slightly larger text for better readability */
  }

  /* Strong/Bold styles */
  strong {
    font-weight: bold;
  }

  /* Underline styles */
  u {
    text-decoration: underline;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1rem 0;

    li p {
      margin: 0.25rem 0;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3 {
    line-height: 1.2;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  /* Theme-specific blockquote styles */
  blockquote {
    border-left: 3px solid;
    margin: 1rem 0;
    padding-left: 1rem;
    font-style: italic;
  }
}

.light .body div[class*="tiptap ProseMirror"] blockquote {
  border-left-color: var(--light-border);
  color: var(--light-text-secondary);
}

.dark .body div[class*="tiptap ProseMirror"] blockquote {
  border-left-color: var(--dark-border);
  color: var(--dark-text-secondary);
}

.transparent .body div[class*="tiptap ProseMirror"] blockquote {
  border-left-color: var(--transparent-border);
  color: var(--transparent-text-secondary);
}

/* Theme-specific toggle row styles */
.light .toggleRow {
  background-color: var(--light-bg-secondary);
}

.dark .toggleRow {
  background-color: var(--dark-bg-secondary);
}

.transparent .toggleRow {
  background-color: var(--transparent-bg-secondary);
}

.toggleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: 8px;
}

.toggleLabel {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

/* Theme-specific toggle label styles */
.light .toggleLabel span {
  color: var(--light-text-primary);
}

.dark .toggleLabel span {
  color: var(--dark-text-primary);
}

.transparent .toggleLabel span {
  color: var(--transparent-text-primary);
}

.toggleLabel span {
  font-weight: 500;
}

/* Theme-specific toggle hint styles */
.light .toggleHint {
  color: var(--light-text-secondary);
}

.dark .toggleHint {
  color: var(--dark-text-secondary);
}

.transparent .toggleHint {
  color: var(--transparent-text-secondary);
}

.toggleHint {
  font-size: 0.85rem;
}

/* Theme-specific form actions styles */
.light .formActions {
  border-top: 1px solid var(--light-border);
}

.dark .formActions {
  border-top: 1px solid var(--dark-border);
}

.transparent .formActions {
  border-top: 1px solid var(--transparent-border);
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  max-width: 800px;
  width: 100%;
  padding: 1rem;
  margin: 1.5rem auto;
}

/* Theme-specific error message styles */
.light .errorMessage {
  color: var(--light-error);
}

.dark .errorMessage {
  color: var(--dark-error);
}

.transparent .errorMessage {
  color: var(--transparent-error);
}

.errorMessage {
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

/* Theme-specific error status styles */
.light .errorStatus {
  color: var(--light-error);
}

.dark .errorStatus {
  color: var(--dark-error);
}

.transparent .errorStatus {
  color: var(--transparent-error);
}

.errorStatus {
  text-align: center;
  margin: 1rem 0;
}

/* Theme-specific success status styles */
.light .successStatus {
  color: var(--light-success);
}

.dark .successStatus {
  color: var(--dark-success);
}

.transparent .successStatus {
  color: var(--transparent-success);
}

.successStatus {
  text-align: center;
  margin: 1rem 0;
}

.controlGroup {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
}

.buttonGroup {
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
}

/* Theme-specific button styles */
.light .buttonGroup button {
  background: #f5f5f5;
  border: 1px solid var(--light-border);
  color: var(--light-text-primary);
}

.dark .buttonGroup button {
  background: var(--dark-bg-secondary);
  border: 1px solid var(--dark-border);
  color: var(--dark-text-primary);
}

.transparent .buttonGroup button {
  background: var(--transparent-bg-secondary);
  border: 1px solid var(--transparent-border);
  color: var(--transparent-text-primary);
}

.buttonGroup button {
  padding: 0.35rem 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
  font-size: 0.9rem;
}

.light .buttonGroup button:hover {
  background: #eee;
}

.dark .buttonGroup button:hover {
  background: #333333;
}

.transparent .buttonGroup button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.buttonGroup button.isActive {
  background: var(--chair-orange);
  color: white;
  border-color: var(--chair-orange);
}

/* Medium/Substack-like styling for the editor */
.dark .body div[class*="tiptap ProseMirror"],
.transparent .body div[class*="tiptap ProseMirror"] {
  font-family: "Georgia", serif; /* More like Medium's serif font */
}

.dark .title,
.transparent .title {
  font-family: "Georgia", serif;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 1.5rem;
    border-radius: 0;
    box-shadow: none;
  }

  .formTitle {
    font-size: 1.5rem;
  }

  .toggleRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .toggleRow > div:last-child {
    align-self: flex-end;
  }
}

/* Replace the existing theme toggle styles with a top bar */
.formTopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
}

.backButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

/* Theme-specific back button styles */
.light .backButton {
  color: var(--light-text-primary);
  background-color: var(--light-bg-secondary);
}

.light .backButton:hover {
  background-color: var(--light-border);
}

.dark .backButton {
  color: var(--dark-text-primary);
  background-color: var(--dark-bg-secondary);
}

.dark .backButton:hover {
  background-color: var(--dark-border);
}

.transparent .backButton {
  color: var(--transparent-text-primary);
  background-color: var(--transparent-bg-secondary);
}

.transparent .backButton:hover {
  background-color: var(--transparent-border);
}

/* Keep the existing theme toggle button styles */
.themeToggleButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

/* Remove the absolute positioning from the theme toggle */
.themeToggle {
  position: static;
}

/* Editor container styles */
.editorContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  overflow: hidden;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  min-height: 350px;
  max-height: 800px;
  resize: vertical;
}

/* Add focus styling for editor container */
.editorContainer:focus-within {
  border-color: #ed7d31; /* chair-orange */
  box-shadow: 0 0 0 2px rgba(237, 125, 49, 0.2);
}

.editorWrapper {
  position: relative;
  min-height: 300px;
  border-radius: 8px;
  transition: all 0.2s ease;
  cursor: text;
}

/* Theme-specific editor wrapper styles */
.light .editorWrapper {
  border: 1px solid var(--light-border);
  background-color: var(--light-input-bg);
}

.dark .editorWrapper {
  border: 1px solid var(--dark-border);
  background-color: var(--dark-input-bg);
}

.transparent .editorWrapper {
  border: 1px solid var(--transparent-border);
  background-color: var(--transparent-input-bg);
}

.editorWrapper:focus-within {
  border-color: var(--chair-orange);
  box-shadow: 0 0 0 2px rgba(255, 123, 0, 0.1);
}

/* Editor content styles */
.body {
  height: 100%;
}

.body .ProseMirror {
  padding: 1rem;
  min-height: 300px;
  height: 100%;
  outline: none;
  overflow-y: auto;
  word-break: break-word;
}

/* Make the editor grow with content */
.editorContent {
  height: 100%;
  min-height: 300px;
  max-height: 800px;
  overflow-y: auto;
}

/* Improve the appearance of the editor content */
.light .body .ProseMirror {
  color: var(--light-text-primary);
}

.dark .body .ProseMirror {
  color: var(--dark-text-primary);
}

.transparent .body .ProseMirror {
  color: var(--transparent-text-primary);
}

/* Add a resize handle for manual resizing */
.editorWrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background: var(--chair-orange);
  cursor: nwse-resize;
  border-radius: 0 0 8px 0;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.editorWrapper:hover::after {
  opacity: 1;
}

/* Add resize functionality */
.editorWrapper {
  resize: vertical;
  overflow: hidden;
}

/* Add mobile-specific styles */
.mobileContainer {
  padding: 0;
}

.mobileFormHeader {
  padding: 1rem 1rem 1.5rem;
  margin: 1rem auto;
}

.mobileFormContent {
  padding: 0 1rem;
}

.mobileFormActions {
  padding: 0 1rem;
  margin: 1.5rem auto;
  position: sticky;
  bottom: 0;
  background-color: inherit;
  padding-bottom: 1rem;
  padding-top: 1rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* Improve the editor for mobile */
.mobileContainer .editorWrapper {
  min-height: 200px;
}

.mobileContainer .body .ProseMirror {
  padding: 0.75rem;
  min-height: 200px;
}

/* Improve the button group for mobile */
.mobileContainer .buttonGroup {
  flex-wrap: wrap;
  justify-content: center;
}

.mobileContainer .buttonGroup button {
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
}

/* Improve form top bar for mobile */
.mobileContainer .formTopBar {
  margin-bottom: 1rem;
}

/* Make the title input more compact on mobile */
.mobileContainer .titleInput {
  font-size: 1.25rem;
  padding: 0.5rem;
}

/* Adjust form section spacing for mobile */
.mobileContainer .formSection {
  margin-bottom: 1rem;
}

/* Adjust form title and description for mobile */
.mobileContainer .formTitle {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.mobileContainer .formDescription {
  font-size: 0.9rem;
}

/* Responsive adjustments for very small screens */
@media (max-width: 375px) {
  .mobileFormActions {
    flex-direction: column;
    gap: 0.75rem;
  }

  .mobileFormActions button {
    width: 100%;
  }

  .mobileContainer .buttonGroup button {
    padding: 0.2rem 0.4rem;
    font-size: 0.8rem;
  }
}

/* Add this to your existing CSS */
.helperText {
  font-size: 0.85rem;
  margin-top: 0.25rem;
  color: var(--light-text-secondary);
}

.dark .helperText {
  color: var(--dark-text-secondary);
}

.transparent .helperText {
  color: var(--transparent-text-secondary);
}

/* Simple container with navbar-brown background */
.simpleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background-color: rgba(
    57,
    45,
    40,
    0.95
  ); /* Using navbar-brown from colors.css */
  color: white;
}

/* Header with back button */
.header {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 2rem;
  position: relative;
}

.header h1 {
  flex-grow: 1;
  margin: 0;
  text-align: center;
  font-size: 1.8rem;
}

.backButton {
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.backButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.deleteButton {
  position: absolute;
  right: 1rem;
  background-color: var(--error);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.deleteButton:hover {
  background-color: var(--error-dark);
}

/* Form layout */
.simpleForm {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  gap: 25px;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.formSection label {
  font-size: 16px;
  font-weight: 500;
}

/* Title input */
.titleInput {
  padding: 12px;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  width: 100%;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.titleInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.titleInput:focus {
  outline: none;
  border-color: #ed7d31; /* chair-orange */
  box-shadow: 0 0 0 2px rgba(237, 125, 49, 0.2);
}

/* Editor styling */
.editorContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  overflow: hidden;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

/* Add focus styling for editor container */
.editorContainer:focus-within {
  border-color: #ed7d31; /* chair-orange */
  box-shadow: 0 0 0 2px rgba(237, 125, 49, 0.2);
}

.menuBar {
  display: flex;
  gap: 5px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  z-index: 2;
}

.menuBar button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.menuBar button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.menuBar button.isActive {
  background-color: #ed7d31; /* chair-orange */
}

.editor {
  position: relative;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 10px;
  cursor: text;
  overflow-y: auto;
}

/* Resize handle */
.resizeHandle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  background: linear-gradient(
    135deg,
    transparent 50%,
    rgba(237, 125, 49, 0.5) 50%
  );
  cursor: ns-resize;
  border-radius: 0 0 8px 0;
}

.resizeHandle:hover {
  background: linear-gradient(
    135deg,
    transparent 50%,
    rgba(237, 125, 49, 0.8) 50%
  );
}

/* Show the resize handle more clearly on hover */
.editor:hover::after {
  background-color: rgba(237, 125, 49, 0.8);
}

.editor [class*="ProseMirror"] {
  min-height: 280px;
  height: 100%;
  color: white;
  font-size: 16px;
  outline: none;
  overflow-y: auto;
}

.editor [class*="ProseMirror"] p {
  margin-bottom: 16px;
  line-height: 1.6;
}

.editor [class*="ProseMirror"] h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.editor [class*="ProseMirror"] h2 {
  font-size: 20px;
  margin-bottom: 16px;
}

.editor [class*="ProseMirror"] blockquote {
  border-left: 3px solid #ed7d31;
  padding-left: 10px;
  margin-left: 0;
  font-style: italic;
}

/* Placeholder text */
.editor [class*="ProseMirror"] p[class*="is-editor-empty"]:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  height: 0;
}

/* Error messages */
.error {
  color: #ff6b6b;
  font-size: 14px;
}

.status {
  text-align: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.errorStatus {
  color: #ff6b6b;
  text-align: center;
}

.successStatus {
  color: #4caf50;
  text-align: center;
}

/* Helper text */
.helper {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

/* Actions bar */
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .simpleContainer {
    padding: 15px;
  }

  .menuBar {
    justify-content: center;
  }

  .actions {
    flex-direction: column-reverse;
  }

  .actions button {
    width: 100%;
  }

  .editor {
    min-height: 250px;
  }
}
