/* Theme Colors */
:root {
  --theme-bg-primary: #ffffff;
  --theme-bg-secondary: #f5f5f5;
  --theme-text-primary: rgba(0, 0, 0, 0.85);
  --theme-text-secondary: rgba(0, 0, 0, 0.6);
  --theme-border: rgba(0, 0, 0, 0.15);
  --theme-hover: rgba(0, 0, 0, 0.05);
  --theme-shadow: rgba(0, 0, 0, 0.1);
}

/* Dark theme overrides */
[data-theme="dark"] {
  --theme-bg-primary: rgba(45, 45, 45, 0.95);
  --theme-bg-secondary: rgba(255, 255, 255, 0.1);
  --theme-text-primary: rgba(255, 255, 255, 0.9);
  --theme-text-secondary: rgba(255, 255, 255, 0.6);
  --theme-border: rgba(255, 255, 255, 0.2);
  --theme-hover: rgba(255, 255, 255, 0.15);
  --theme-shadow: rgba(0, 0, 0, 0.2);
}

/* Navbar-brown theme overrides - matching the StoryForm colors */
[data-theme="navbar-brown"] {
  --theme-bg-primary: rgba(57, 45, 40, 0.95);
  --theme-bg-secondary: rgba(0, 0, 0, 0.2);
  --theme-text-primary: rgba(255, 255, 255, 0.9);
  --theme-text-secondary: rgba(255, 255, 255, 0.6);
  --theme-border: rgba(255, 255, 255, 0.2);
  --theme-hover: rgba(255, 255, 255, 0.15);
  --theme-shadow: rgba(0, 0, 0, 0.3);
}

/* Base container */
.container {
  position: relative;
  border-radius: 8px;
  padding: 8px 12px;
  min-height: 45px;
  display: flex;
  align-items: center;
  cursor: text;
  margin: 16px 0;
  background: var(--theme-bg-primary);
  border: 1px solid var(--theme-border);
  transition: all 0.2s ease;
}

.container.focused {
  border-color: var(--theme-border);
  background: var(--theme-bg-secondary);
  box-shadow: 0 0 0 2px var(--theme-shadow);
}

/* Navbar-brown specific styles */
[data-theme="navbar-brown"] .container {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-theme="navbar-brown"] .container.focused {
  border-color: #ed7d31;
  box-shadow: 0 0 0 2px rgba(237, 125, 49, 0.2);
}

[data-theme="navbar-brown"] .tagBadge {
  background: rgba(237, 125, 49, 0.2);
  border-color: rgba(237, 125, 49, 0.4);
  color: white;
}

[data-theme="navbar-brown"] .tagBadge:hover {
  background: rgba(237, 125, 49, 0.3);
}

[data-theme="navbar-brown"] .tagInput {
  color: white;
}

[data-theme="navbar-brown"] .tagInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Tag Badge */
.tagBadge {
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.95em;
  gap: 8px;
  cursor: pointer;
  background: var(--theme-bg-secondary);
  border: 1px solid var(--theme-border);
  color: var(--theme-text-primary);
  transition: all 0.2s ease;
}

.tagBadge:hover {
  background: var(--theme-hover);
}

/* Tag Input */
.tagInputContainer {
  position: relative;
  width: 100%;
}

.tagInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 54px; /* Match SimpleCombobox height */
  padding: 8px 12px;
  border: 2px solid rgba(255, 255, 255, 0.3); /* Match SimpleCombobox border */
  border-radius: 8px; /* Match SimpleCombobox border-radius */
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Match SimpleCombobox background */
  transition: all 0.2s ease; /* Match SimpleCombobox transition */
  gap: 8px;
}

.tagInput:focus-within {
  border-color: var(--chair-orange); /* Match SimpleCombobox focus border */
  background-color: rgba(
    255,
    255,
    255,
    0.15
  ); /* Match SimpleCombobox focus background */
}

.tagInput:hover:not(:focus-within) {
  background-color: rgba(
    255,
    255,
    255,
    0.12
  ); /* Add hover state similar to SimpleCombobox */
}

/* .tagInput::placeholder {
  color: var(--theme-text-secondary);
} */

/* Dropdown */
.tagDropdown {
  background: var(--theme-bg-primary);
  border: 1px solid var(--theme-border);
  border-radius: 8px;
  box-shadow: 0 4px 12px var(--theme-shadow);
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 8px;
  padding: 4px 0;
  /* Prevent text selection in dropdown to avoid focus issues */
  user-select: none;
}

/* Tag Option */
.tagOption {
  padding: 10px 16px;
  cursor: pointer;
  color: var(--theme-text-primary);
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
}

.tagName {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.tagNameText {
  flex: 1;
  min-width: 0; /* Allow text to truncate if needed */
}

.scoreIndicator {
  font-size: 0.8em;
  padding: 2px 6px;
  border-radius: 4px;
  background: var(--theme-bg-secondary);
  color: var(--theme-text-primary);
  white-space: nowrap;
}

.selectedIndicator {
  color: inherit;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.tagOptionSelected {
  background: var(--theme-bg-secondary);
  border-left: 3px solid var(--chair-orange, #ff6b35);
  font-weight: 500;
}

.tagOption:hover:not(.tagOptionSelected) {
  background: var(--theme-hover);
}

/* Metadata */
.tagMetadata {
  display: flex;
  gap: 12px;
  margin-top: 8px;
  font-size: 0.8em;
  color: var(--theme-text-secondary);
}

.metadataItem {
  padding: 2px 6px;
  border-radius: 4px;
  background: var(--theme-bg-secondary);
}

/* Selected Tags List */
.selectedList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
  align-items: center;
  padding: 4px 0;
}

/* Remove button */
.removeBtn {
  font-size: 1.1em;
  line-height: 1;
  color: var(--theme-text-secondary);
  transition: color 0.2s ease;
}

.removeBtn:hover {
  color: var(--theme-text-primary);
}

/* Section headers */
.sectionTitle {
  display: block;
  padding: 8px 16px;
  color: var(--theme-text-secondary);
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Loading state */
.loadingItem {
  display: flex;
  justify-content: center;
  padding: 16px;
  color: var(--theme-text-secondary);
}

/* Add light mode styles for the selected indicator */
.tagOption.light .selectedIndicator {
  color: var(--theme-text-primary);
  background: var(--theme-hover);
}

.tagDescription {
  font-size: 0.85em;
  color: var(--theme-text-secondary);
  margin: 4px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
}

/* Adjust spacing for the metadata when description is present */
.tagDescription + .tagMetadata {
  margin-top: 4px;
}

/* Improve focus states */
.container:focus-within {
  border-color: var(--theme-border);
  box-shadow: 0 0 0 2px var(--theme-shadow);
}

/* Add a scrollbar style for the dropdown */
.tagDropdown::-webkit-scrollbar {
  width: 8px;
}

.tagDropdown::-webkit-scrollbar-track {
  background: var(--theme-bg-secondary);
  border-radius: 4px;
}

.tagDropdown::-webkit-scrollbar-thumb {
  background: var(--theme-border);
  border-radius: 4px;
}

.tagDropdown::-webkit-scrollbar-thumb:hover {
  background: var(--theme-text-secondary);
}

/* Improve empty state */
.dropdownSection:empty + .loadingItem:not(:first-child) {
  border-top: 1px solid var(--theme-border);
}

/* Navbar-brown dropdown styles */
[data-theme="navbar-brown"] .tagDropdown {
  background: rgba(57, 45, 40, 0.98);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

[data-theme="navbar-brown"] .tagOption {
  color: white;
}

[data-theme="navbar-brown"] .tagOption:hover:not(.tagOptionSelected) {
  background: rgba(255, 255, 255, 0.1);
}

[data-theme="navbar-brown"] .tagOptionSelected {
  background: rgba(237, 125, 49, 0.2);
}

[data-theme="navbar-brown"] .sectionTitle {
  color: rgba(255, 255, 255, 0.7);
}

[data-theme="navbar-brown"] .tagDescription {
  color: rgba(255, 255, 255, 0.7);
}

[data-theme="navbar-brown"] .metadataItem {
  background: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

[data-theme="navbar-brown"] .scoreIndicator {
  background: rgba(237, 125, 49, 0.2);
  color: white;
}
