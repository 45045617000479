.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--navbar-height));
  padding: 2rem;
  background: var(--surface-secondary);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  text-align: center;
  color: var(--text-inverse);
  background: transparent;
  max-width: 600px;
  margin: 40px auto;
  border-radius: 8px;
}

.header {
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
}

.title {
  font-size: 2.2rem;
  margin-bottom: 15px;
  font-weight: 600;
  color: var(--text-inverse);
}

.content {
  width: 100%;
}

.warningSection {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.25rem;
  background: var(--surface-warning);
  border: 1px solid var(--border-warning);
  border-radius: 12px;
  margin-bottom: 2rem;
  text-align: left;
}

.warningIcon {
  font-size: 1.5rem;
  color: var(--text-warning);
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.warningTitle {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.warningText {
  color: var(--text-secondary);
}

.infoSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.infoItem {
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  background: var(--surface-secondary);
  border: 1px solid var(--border-default);
  border-radius: 12px;
  text-align: left;
}

.infoIcon {
  font-size: 1.5rem;
  color: var(--text-secondary);
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.infoItem h3 {
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
  color: var(--text-primary);
  font-weight: 600;
}

.infoDate {
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.reason {
  margin: 0.5rem 0;
  color: var(--text-secondary);
}

.type {
  font-size: 0.9rem;
  color: var(--text-tertiary);
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.typeIcon {
  font-size: 1rem;
}

.deadline {
  color: var(--chair-orange);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.note {
  font-size: 0.9rem;
  color: var(--text-tertiary);
  font-style: italic;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2.5rem;
}

.reactivateButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--chair-orange);
  color: var(--text-inverse);
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.reactivateButton:hover {
  background-color: var(--chair-orange-dark);
  transform: translateY(-1px);
}

.cancelButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  padding: 0.75rem 1.5rem;
  transition: all 0.2s ease;
}

.cancelButton:hover {
  color: white;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
}

.reactivateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  max-width: 600px;
  margin: 40px auto;
  background-color: var(--color-background-light);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-medium);
  text-align: center;
}

.icon {
  font-size: 3rem;
  color: var(--chair-orange);
  margin-bottom: 20px;
}

.message {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.85);
}

.deadlineInfo {
  font-size: 1rem;
  font-style: italic;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 25px;
}

.warningBox {
  background-color: rgba(var(--status-banned-rgb), 0.1);
  border: 1px solid var(--status-banned);
  border-radius: 6px;
  padding: 15px;
  margin: 25px 0;
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 100%;
}

.warning {
  color: var(--color-error);
  margin-top: 10px;
  margin-bottom: 25px;
  font-weight: bold;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
  width: 100%;
  max-width: 400px;
}

@media (min-width: 600px) {
  .buttonGroup {
    flex-direction: row;
    justify-content: center;
  }
}

.buttonGroup > button {
  width: 100%;
}

@media (min-width: 600px) {
  .buttonGroup > button {
    width: auto;
  }
}

/* Responsive adjustments if needed */
@media (max-width: 600px) {
  .buttonGroup {
    flex-direction: column;
    align-items: center;
  }

  .buttonGroup > * {
    /* Apply to direct children */
    width: 80%; /* Make buttons take more width on smaller screens */
  }
}

/* Add new styles for the register button */
.registerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  background-color: var(--chair-orange);
  color: var(--text-inverse);
  text-decoration: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.registerButton:hover {
  background-color: var(--chair-orange-dark);
}

.buttonIcon {
  font-size: 1.1rem;
}
