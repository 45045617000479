/* packages/client/src/components/Modal/Modal.module.css */

/* Style for the FloatingOverlay backdrop */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: grid; /* Use grid for easy centering */
  place-items: center; /* Center the modal box */
  z-index: var(--overlay-z-index);
  overflow-y: auto; /* Allow scrolling if modal is too tall */
}

.box {
  background-color: white;
  padding: 20px;
  display: flex; /* Enable flex layout */
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align items to the start (left) */
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  min-width: 500px;
  max-width: 500px;
  max-height: 90vh; /* Use viewport height for max-height */
  overflow-y: auto; /* Allow internal scrolling if content overflows */
}

.box > * {
  width: 100%;
}

.box {
  color: black;
}

.box .btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.box .btnContainer .close-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: filter 0.1s ease; /* Smooth transition for the filter */
}

.box .btnContainer .close-btn:hover {
  /* background-color: #45a049; */
  filter: brightness(90%);
}

.modalHeader {
  font-size: 1.2em; /* Adjust size as needed */
  font-weight: bold; /* Make the header bold */
  margin-bottom: 1rem;
  color: var(--chair-orange);
}

.center {
  display: flex;
  justify-content: center;
}

.modal .modalBody p {
  color: #666; /* Lighter color for the body text */
}

.modal .modalBody {
  font-size: 1em; /* Regular font size for the body */
  color: #666; /* Lighter color for the body text */
  line-height: 1.75rem; /* Spacing between lines */
  text-align: left; /* Align text to the left */
  margin-bottom: 1rem;
}

.modalBody ul {
  padding-left: 20px; /* Add padding to the left for list items */
  margin: 0; /* Remove default margin */

  overflow-wrap: break-word; /* Ensure long words break to the next line */
}

@media (max-width: 768px) {
  .box {
    min-width: 90%;
    max-width: 90%;
  }
}

.close-btn {
  position: relative;
  display: flex;
}

.close-btn > button {
  border: none;
  margin-left: auto;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
  color: #777;
  padding: 0 0.5em;
  line-height: 0.5em;
}
